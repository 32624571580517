import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://373ed127db64642b406ac9d5605f32fb@o1423748.ingest.us.sentry.io/4508167209877504",
  debug: false,
  environment: "staging",
  release: "website@staging.0c397b96540800353ba2a1ac217c69aec7fd9970",
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});